<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <div slot="actions">
      <div class="field has-addons">
        <div class="is-flex mr-2">
          <b-button
            v-if="$device.mobile"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon
              icon="magnify"
              size="is-small"
            />
          </b-button>
          <b-input
            ref="search"
            v-model="search_sku"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            :placeholder="$t('search')+' SKU'"
            icon="magnify"
            rounded 
            @blur="hideMobile()"
            @input="getInventory()"
          />
        </div>
        <b-dropdown
          ref="dropdown"
          aria-role="list"
          position="is-bottom-left"
          :close-on-click="false"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              :size="$device.mobile ? 'is-small' : ''"
              type="is-default"
              rounded
            />
          </div>

          <b-dropdown-item
            v-if="$can('read', 'purchasing.inventory.export')"
            aria-role="listitem"
            @click="exportModal=true"
          >
            <b-icon
              icon="download"
              size="is-small"
            />
            {{ $t("export_excel") }}
          </b-dropdown-item>
          <!-- 
          <b-dropdown-item separator /> -->
        </b-dropdown>
      </div>
    </div>
    <!-- <div slot="actions">
      <div class="field has-addons">
        <div class="is-flex">
          <b-button
            v-if="$device.mobile"
            style="z-index: 9"
            size="is-small"
            rounded
            @click="showMobile()"
          >
            <b-icon icon="magnify" size="is-small" />
          </b-button>
          <b-input
            ref="search"
            v-model="search"
            :placeholder="$t('search')"
            icon="magnify"
            :size="$device.mobile ? 'is-small' : ''"
            :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
            rounded
            @blur="hideMobile()"
          />
        </div>
      </div>
    </div> -->

    <Card
      v-if="$can('read', 'purchasing.inventory')"
      content-class="c-table"
    >
      <div class="header-container">
        <div class="is-flex">
          <b-select-validation
            v-model="warehouse"
            required
            size="is-small"
            :label="$tc('warehouse', 1)"
            hide-details
            style="min-width: 150px;"
            @input="getInventory"
          >
            <template>
              <option
                v-for="opt in warehouses"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  `${opt.country.country} - ${opt.currency.code}`
                }}
              </option>
            </template>
          </b-select-validation>

          <b-select-validation
            v-model="type"
            required
            size="is-small"
            class="ml-2"
            :label="$tc('type', 1)"
            :disabled="outsourced && !done"
            hide-details
            style="min-width: 150px;"
            @input="getInventory"
          >
            <template>
              <option
                v-for="opt in types"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
          <!-- <b-datepicker
            v-model="created_at"
            :mobile-native="false"
            @input="getInventory()"
          > -->
          <!-- <template v-slot:trigger>
              <b-button
                icon-left="calendar-today"
                type="is-primary"
                size="is-small"
                class="ml-2"
                outlined
              />
            </template>
          </b-datepicker>  -->
        </div>


        <div
          v-if="outsourced"
          class="is-flex"
        >
          <DatesPicker
            class="mt-1 mr-3"
            @change="changeDates"
          />
            
          
          <b-field>
            <p class="control">
              <b-button
                size="is-small"
                :type="!done ? 'is-primary' : ''"
                @click="done = false"
              >
                {{ $t("to_process") }}
              </b-button>
            </p>
            <p class="control">
              <b-button
                size="is-small"
                :type="done ? 'is-primary' : ''"
                @click="done = true"
              >
                {{ $t("processed") }}
              </b-button>
            </p>
          </b-field>
        </div>
        <div
          v-else
          class="is-flex"
        >
          <DatesPicker
            class="mt-1 mr-3"
            @change="changeDates"
          />

          <b-button
          
            size="is-small"
            type="is-primary"
            icon-left="plus"
            outlined
            @click="
              openDrawer('catalogInventory', {
                filename: '',
                inventory_id: null,
                processed: false,
                warehouse_id: warehouse,
                warehouses: warehouses,
                done: false,
                add: true,
              })
            "
          >
            {{ $t("add") }}
          </b-button>
        </div>
      </div>
      <hr class="my-0">
      <b-table
        :loading="loading"
        :data="items"
        hoverable
        :current-page="page"
        :per-page="perPage"
        :total="total"
        :row-class="
          (row, index) => (row.id == updated_inventory ? 'bg-updated' : '')
        "
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            v-slot="props"
            :label="head.text"
            :field="head.value"
            :width="head.width"
            :centered="head.centered"
            header-class="sticky-header"
          >
            <template v-if="head.value == 'type'">
              {{
                props.row.type || "-"
              }}
            </template>
            <template v-else-if="head.value == 'order_id'">
              {{
                props.row.order_id || "-"
              }}
            </template>
            <template v-else-if="head.value == 'memo'">
              <span>{{ props.row.memo || '-' }}</span>
            </template>
            <template v-else-if="head.value == 'id'">
              <a
                v-if="$can('read', 'purchasing.inventory')"
                href="javascript:;"
                @click="
                  openDrawer('catalogInventory', {
                    filename: props.row.file_name || props.row.memo,
                    inventory_id: props.row.id,
                    inventory_type: props.row.type,
                    processed: done || !outsourced,
                    warehouse_id: warehouse,
                    warehouses: warehouses,
                    inventory: props.row,
                    done: done,
                  })
                "
              >{{ props.row.id }}</a>
              <span v-else>{{ props.row.id }}</span>
            </template>

            <template v-if="head.value == 'created_at'">
              <span v-if="props.row.created_at">
                {{ props.row.created_at.formatted }}
                <span class="has-text-grey-light">{{
                  props.row.created_at.time
                }}</span>
              </span>
              <template v-else>
                -
              </template>
            </template>

            <template v-if="head.value == 'agent'">
              {{
                props.row.agent
                  ? `${props.row.agent.fname} ${props.row.agent.lname}`
                  : "-"
              }}
            </template>
          </b-table-column>
        </template>

        <EmptyBlock
          slot="empty"
          icon="domain"
        />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="page"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (page = val)"
      />
    </Card>


    <b-modal
      v-model="exportModal"
      :width="420"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("which_data_to_export") }}</b>
          </div>
          <hr class="my-0">
        </div>
        <div class="columns is-multiline is-1 p-1">
          <div class="column is-12">
            <b-button
              type="is-primary"
              expanded
              :loading="exportLoading"
              :disabled="exportLoading"
              @click="exportExcel(perPage)"              
            >
              {{ $t("current_results") }}
            </b-button>
          </div>
          <div class="column is-12">
            <b-button
              type="is-primary"
              expanded
              :loading="exportLoading"
              :disabled="exportLoading"
              @click="exportExcel('all')"
            >
              {{ $t("all_results") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
  </Page>
</template>

<script>
import DatesPicker from "@/components/forms/elements/DatesPicker";

export default {
  components: {
    DatesPicker
  },
  data() {
    return {
      warehouses: [],
      warehouse: null,
      types: [],
      type: null,
      outsourced: true,
      total: 0,
      loading: true,
      headers: [
        { text: this.$i18n.t("id"), align: "left", value: "id" },
        // { text: this.$i18n.t("type"), align: "left", value: "type" },
        { text: this.$i18n.tc("order", 1), align: "left", value: "order_id" },
        { text: this.$i18n.t("memo"), align: "left", value: "memo" },
        { text: this.$i18n.tc("agent", 1), align: "left", value: "agent" },
        { text: this.$i18n.t("date"), align: "left", value: "created_at" }
      ],

      items: [],
      page: 1,
      perPages: [10, 20, 50, 100, 200],
      perPage: 50,
      done: false,
      search: "",
      searchFocused: false,
      search_sku: "",
      date_filter: {},
      exportModal: false,
      exportLoading: false,
      updated_inventory: null,
      init: true,
    };
  },
  watch: {
    done() {
      this.getInventory();
    },
    search() {
      this.getInventory();
    },
    page() {
      this.getInventory();
    },
    perPage() {
      this.getInventory();
    }
  },
  created() {
    this.updateTitle(this.$tc("inventory", 2));
    this.getInventory();
    this.$bus.$on("update-inventory", inventory_id => {
      this.items = this.items.filter(o => o.id != inventory_id);
    });
    this.$bus.$on("create-inventory", params => {
      this.items.unshift(params.item.inventory);
      this.updated_inventory = params.item.inventory.id;
      setTimeout(() => {
          this.updated_inventory = null;
        }, 2000);
    });
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    changeDates(dates) {
      this.date_filter.from = dates.date.from;
      this.date_filter.to = dates.date.to;
      if (!this.init) {
        this.getInventory();
      } else {
        this.init = false;
      }
    },
    getInventory() {
      if (this.warehouse) {
        let warehouse = this.warehouses.find(w => w.id == this.warehouse);
        this.outsourced = !!warehouse.outsourced;
      }
      this.loading = true;
      let url_params = `?page=${this.page}&per_page=${this.perPage}`;
      url_params += this.outsourced
        ? `&processed=${this.done ? 1 : 0}`
        : "&processed=1";

      if (this.warehouse) url_params += `&warehouse_id=${this.warehouse}`;

      if (this.date_filter.from && this.date_filter.to) {
        url_params +=
          "&date_from=" +
          this.date_filter.from +
          "&date_to=" +
          this.date_filter.to;
      } 

      let searchArr = [];

      if (this.search_sku) {
        searchArr.push({
          id: "sku",         
          selected: this.search_sku
        });
      }

      if (this.type) {
        searchArr.push({
          id: 'type',
          selected: this.type
        });
      };

      if (searchArr.length)
        url_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.$axios
        .get("purchasing/inventory" + url_params)
        .then(res => {
          this.items = res.data.list ? res.data.list : [];
          this.total = res.data.pagination.total;
          if (!this.warehouse) {
            this.warehouses = res.data.filters.warehouses;
            this.warehouse =
              res.data.filters.warehouses[0] &&
              res.data.filters.warehouses[0].id;
            this.outsourced =
              res.data.filters.warehouses[0] &&
              !!res.data.filters.warehouses[0].outsourced;
          }
          if (!this.type) {
            this.types = res.data.filters.types;
            this.type =
              res.data.filters.types[0] &&
              res.data.filters.types[0].id;
          }
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loading = false));
    },
    exportExcel(perPage) {
      this.exportLoading = true;

      let url_params = this.outsourced
        ? `?processed=${this.done ? 1 : 0}`
        : "?processed=1"; ;

      url_params += `&page=${this.page}&per_page=${perPage}`;

      if (this.warehouse) url_params += `&warehouse_id=${this.warehouse}`;  
      
      if (this.date_filter.from && this.date_filter.to) {
        url_params +=
          "&date_from=" +
          this.date_filter.from +
          "&date_to=" +
          this.date_filter.to;
      } 

      let searchArr = [];

      if (this.search_sku) {
        searchArr.push({
          id: "sku",         
          selected: this.search_sku
        });
      }

      if (this.type) {
        searchArr.push({
          id: 'type',
          selected: this.type
        });
      };

      if (searchArr.length)
        url_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

      this.$axios
        .get("purchasing/inventory/export" + url_params)
        .then(res => {
          const url = URL.createObjectURL(
            new Blob([res.data.file], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.data.filename);
          document.body.appendChild(link);
          link.click();
          this.exportModal = false;
        })
        .catch(e => this.clientError(e))
        .finally(() => ( this.exportLoading = true));
    },
  }
};
</script>
